(function($) {

    $.fn.constructionDetail = function() {

        var selectedId = 0;
        var col1small='col-sm-2';
        var col1big='col-sm-12';
        var col2small='col-sm-7';
        var col2big='col-sm-10';
        var col3big='col-sm-3';
        var PRODUCTION_PROCESS_WORKFLOW_SPREADSHEET = ".production-process-workflow";
        var $constructionDetailEditForm = $(".fpConstructionDetailEdit");
        var MATERIALS_EDITING_CLASS = "construction-detail-editing-materials";

        var loadConstructionDetail = function(detail) {
            var detailRow = detail.parent();
            $(".fpConstructionDetailEdit").html(LOADING);
            if(detailRow.length > 0) {
                detailRow.addClass("construction-detail-selected");
                selectedId = detailRow.data("id");
                var ajaxData = $.extend({}, iAjaxData, {
                    url: detailRow.data("url"),
                    success: loadConstructionDetailSuccess
                });
                $.ajax(ajaxData);
            }
            else {
                loadBlank();
            }
        };

        var loadConstructionDetailOperation = function(trigger) {
            var ajaxData = $.extend({}, iAjaxData, {
                url: trigger.data("url"),
                success: loadConstructionDetailOperationSuccess
            });
            $.ajax(ajaxData);
        };

        collapseSublist = function(id) {
            var elements = $(".construction-detail-item[data-parent-id='" + id + "']").not("[data-id='" + id + "']");
            elements.addClass("fpDisplayNone");
            elements.each(function() {
                collapseSublist($(this).parent().data("id"));
                $(this).find(".fa-caret-down").removeClass("fa-caret-down").addClass("fa-caret-right");
            })
        };

        expandSublist = function(id) {
            var parent = $(".construction-detail-item[data-id='" + id + "']");
            var elements = $(".construction-detail-item[data-parent-id='" + parent.data("id") + "']").not("[data-id='" + parent.data("id") + "']");
            elements.removeClass("fpDisplayNone");
            parent.find(".fa-caret-right").removeClass("fa-caret-right").addClass("fa-caret-down");
            if(parent.data("id") !== parent.data("parent-id")) {
                expandSublist(parent.data("parent-id"));
            }
        };

        loadBlank = function() {
            $(".fpConstructionDetailTable").addClass(col1big).removeClass(col1small);
            $(".fpConstructionDetailEdit").removeClass(col2big).html("");
        };

        onConstructionDetailClick =  function(contextElement) {
            closeConstructionDetailOperation();
            if ($(this).find(".fa-caret-down").length > 0) {
                collapseSublist($(this).parent().data("id"));
                $(this).find(".fa-caret-down").removeClass("fa-caret-down").addClass("fa-caret-right");
                $(this).removeClass("construction-detail-selected");
                loadConstructionDetail($(this));
            }
            else {
                expandSublist($(this).parent().data("id"));
                $(this).find(".fa-caret-right").removeClass("fa-caret-right").addClass("fa-caret-down");

                $(".construction-detail-selected").removeClass("construction-detail-selected");

                loadConstructionDetail($(this));
            }
        };

        onConstructionDetailOperationClick=function(contextElem){
            selectMaterialsEditButton($(contextElem.currentTarget));
            iShowMask($constructionDetailEditForm);
            loadConstructionDetailOperation($(contextElem.currentTarget));                 
        };

        selectMaterialsEditButton = function($button) {
            unselectMaterialsEditButtons();
            $button.addClass(MATERIALS_EDITING_CLASS);
            $button.find("i")
                .removeClass("fa-edit")
                .addClass("fa-folder-open");
        };

        unselectMaterialsEditButtons = function() {
            $("." + MATERIALS_EDITING_CLASS).each(function() {
                $(this).removeClass(MATERIALS_EDITING_CLASS);
                $(this).find("i")
                    .removeClass("fa-folder-open")
                    .addClass("fa-edit");
            });
        };

        loadConstructionDetailSuccess = function(data) {
            var edit = $(".fpConstructionDetailEdit");
            edit.html(data);
            $(".fpConstructionDetailTable").removeClass(col1big).addClass(col1small);
            edit.addClass(col2big);

            constructionDetailsAppendEvents();
        };

        onCancelConstructionDetailClick = function(){
            closeConstructionDetailOperation();
        }

        onTableChange = function() {
            if(selectedId != 0) {
                if(selectedId !=null){
                    expandSublist(selectedId);
                    loadConstructionDetail($(".construction-detail-item[data-id='" + selectedId + "'] > .construction-detail-item-name"));
                }
            }
            else {
                loadBlank();
            }
            $(".construction-detail-add").not(":first").remove();
            $(".construction-detail-item-name, .construction-detail-item-new").unbind("click").click(onConstructionDetailClick);
            $(".load-products-to-order").unbind("click").click(loadProductsToOrder);
        };

        showUpload = function() {
            iNewWindow($(this).data("url"),"attachment", 750, 200);
        };

        var closeConstructionDetailOperation= function(){
            unselectMaterialsEditButtons();
            $(".fpConstructionDetailEdit").addClass(col2big).removeClass(col2small);
            $(".fpConstructionDetailOperation").removeClass(col3big).html('');
        }

        saveConstructionDetail = function() {
            var $form = fpGetForm.call(this);

            iShowMask($form);
            unselectMaterialsEditButtons();
            var ajaxData=$.extend({},iAjaxData,{
                _this : $form,
                data : iDefaultFormDataGenerator({tag:$form}),
                url : $form.attr("action"),
                success: formSuccess
            });
            $.ajax(ajaxData);
        };

        copyConstructionDetail = function() {
            var numberOfCopies = prompt(i18n("constructionDetail.copy.numberOfCopies"));
            if(numberOfCopies) {
                var $form = fpGetForm.call(this);
                iShowMask($form);
                var ajaxData=$.extend({},iAjaxData,{
                    _this : $form,
                    url : $(this).data("url"),
                    data: {
                        numberOfCopies: parseInt(numberOfCopies)
                    },
                    success: formSuccess
                });
                $.ajax(ajaxData);
            }
        };

        deleteConstructionDetail = function() {
            if(confirm(i18n('confirmDeletion'))) {
                var $form = fpGetForm.call(this);
                iShowMask($form);
                var ajaxData=$.extend({},iAjaxData,{
                    _this : $form,
                    url : $(this).data("url"),
                    success: formSuccess
                });
                $.ajax(ajaxData);
            } else {
                return false;
            }
        };

        formSuccess = function(data, textStatus, jqXHR) {
            if(manageExceptions.call(this, jqXHR, data)) {
                return false;
            }

            if(jqXHR.getResponseHeader("requestOK") === "true") {
                alertify.success(i18n('ChangeSaved'));
                selectedId = jqXHR.getResponseHeader("id");
                reloadConstructionDetailsTable();
            }
            else {
                $(".fpConstructionDetailEdit").html(data);
                constructionDetailsAppendEvents();
            }
            closeConstructionDetailOperation();
        },

        getMaterialsAutocomplete = function(){
           return $.extend({},Autocomplete.common, {
                source: function (request, response) {
                    $.ajax({
                        url: $(this.element).attr("data-url"),
                        dataType: "json",
                        data: {
                            term: request.term
                        },
                        success: function (data) {
                            response($.map(data, function (item) {
                                return {
                                    label: item.name,
                                    value: item.id,
                                    unit: item.unit,
                                    minimalAmount: item.minimalAmount,
                                    actualAmount: item.actualAmount
                                };
                            }));
                        }
                    });
                }
            })
        },

        markMaterialsUnderMin = function(list, item){
            var listItem = $("<li></li>")
            .data("item.autocomplete", item)
            .append("<a>" +  item.label + " ( " + item.actualAmount + " " + item.unit + " )</a>")
            .appendTo(list);

            if (parseFloat(item.actualAmount) < parseFloat(item.minimalAmount)) {
                listItem.addClass("raw-material-under-min");
            }

            return listItem;
        },

        loadConstructionDetailOperationSuccess = function(data, textStatus, jqXHR) {
            iHideMask($constructionDetailEditForm);
            if(manageExceptions.call(this, jqXHR, data)) {
                return false;
            }

            var $edit = $(".fpConstructionDetailOperation");
            $(".fpConstructionDetailEdit").addClass(col2small).removeClass(col2big);
            $edit.addClass(col3big).html(data);
            constructionDetailOperationAppendEvents();
        };
        
        bindEditMaterialsRow = function(event){
            $(".iSpreadsheetEditResourcesRow").unbind('click').on('click', onConstructionDetailOperationClick);
        },

        bindAutocomplete = function(context) {
            var $materialsAutocomplete = $(context).find("[name='materialName']").autocomplete(getMaterialsAutocomplete());
            $(context).find("input[type='number']").change(validateMinAmount);

            if($materialsAutocomplete.size() > 0) {
                $materialsAutocomplete.each(function() {
                    $(this).data("ui-autocomplete")._renderItem = markMaterialsUnderMin;
                });
            }
        },

        bindOperationParametersChange = function($row){
            var productionProcessOperationId = $row.find("input[name='productionProcessOperationId']").val();
            var $destinationItem = $(PRODUCTION_PROCESS_WORKFLOW_SPREADSHEET).find("input[value='" + productionProcessOperationId + "']").parent();

                $row.find("input[name='effort']").change(function() {
                    $destinationItem.find("input[name='effort']").val($(this).val());
                });
                $row.find("input[name='machinePreparationTime']").change(function() {
                    $destinationItem.find("input[name='machinePreparationTime']").val($(this).val());
                });
                $row.find("textarea[name='notes']").change(function() {
                    $destinationItem.find("input[name='notes']").val($(this).val());
                });
                $row.find("select[name='readiness']").change(function() {
                    $destinationItem.find("input[name='readiness']").val($(this).val());
                });
        },

        constructionDetailOperationAppendEvents = function() {
            $(".construction-detail-operation-materials .fpSaveButton").click(saveConstructionDetail);
            $(".construction-detail-operation-materials .fpCancelButton").click(onCancelConstructionDetailClick);

            var $materialsSpreadsheet = $(".construction-detail-operation-materials .iSpreadsheet[data-name='constructionDetailMaterials']");
            $materialsSpreadsheet.spreadsheet(bindAutocomplete);
            bindAutocomplete($materialsSpreadsheet);
        },

        loadProductsToOrder = function(e) {
            var url = $(this).data("url");
            var ids = fpGetCheckedIds("constructionDetail[]");
            var ajaxData = $.extend({},iAjaxData,{
                url : url,
                data: {
                    productIds: ids,
                },
                success: function(data, textStatus, jqXHR) {
                    if(data.length > 0) {
                        $(".order-add-form").order.fillOrderItemFromCatalogue(data);
                        alertify.success(i18n("LoadedSuccess"));
                    } else {
                        alertify.error(i18n('NoData'));
                    }
                }
            });

            e.stopPropagation();
            alertify.warning(i18n("Loading"));
            $.ajax(ajaxData);
        },
        toggleAddProductsButtonActivity = function() {
            var productsChecked = $("[name='constructionDetail[]']:checked").size() > 0;
            $(".load-products-to-order").attr("disabled", !productsChecked);
        },

        constructionDetailsAppendEvents = function() {
            $(".construction-detail-form .fpFormTabs").tabs({activate: adjustTextareas});
            $(".construction-detail-form .accordion" ).accordion({active : 0, collapsible: true, heightStyle: "content"});
            $(".construction-detail-form .fpSaveButton").click(saveConstructionDetail);
            $(".construction-detail-form .fpCopyButton").click(copyConstructionDetail);
            $(".construction-detail-form .fpDeleteButton").click(deleteConstructionDetail);
            $(".construction-detail-form .iUploadLink").click(showUpload);
            $(".construction-detail-form .iDeleteFileLink").click(iDeleteFile);
            $("*[title]:not([data-toggle='popover'])").tooltip();
            $("input[type='number']").change(validateMinAmount);

            $(PRODUCTION_PROCESS_WORKFLOW_SPREADSHEET).on("dirty-data", disableOperationIntensityTab);

            var $operationsSpreadsheet = $(".construction-detail-form .iSpreadsheet[data-name='constructionDetailOperations']");
            $operationsSpreadsheet.spreadsheet().loadProcess();

            var $tagsSpreadsheet = $(".construction-detail-form .iSpreadsheet[data-name='tags']");
            $tagsSpreadsheet.spreadsheet();

            var $parametersSpreadsheet = $(".construction-detail-form .iSpreadsheet[data-name='constructionDetailOperationsParameters']");
            $parametersSpreadsheet.find(".form-group").each(function() {
                bindOperationParametersChange($(this));
            });

            bindEditMaterialsRow();
        },

        reloadConstructionDetailsTable = function() {
            var $form = $(".fpConstructionDetailTable > form");
            reloadTable($form);
        },

        disableOperationIntensityTab = function(e) {
            $(".fpFormTabs").tabs({disabled: [1]});
            $(".production-process-not-saved").show();
        },

        importFromBaseLinker = function(){
            if(confirm(i18n('confirmAction'))) {
                var $form = $(this).closest(".factory-view").find("form").eq(0).find(".fpDataTable");
                iShowMask($form);
                var ajaxData = $.extend({},iAjaxData,{
                    _this: $form,
                    url : $(this).data("url"),
                    success: importFromBaseLinkerSuccess
                });
                $.ajax(ajaxData);
            }
        },

        importFromBaseLinkerSuccess = function (data, textStatus, jqXHR) {
            if(manageExceptions.call(this, jqXHR, data)) {
                return false;
            } else {
                reloadConstructionDetailsTable();
                alertify.success(i18n('baselinker.importSuccess'));
            }
        };

        filteringUnderOptimal = function(e) {
            if($(this).is(".active")) {
                $("[name='criteria.onlyUnderOptimal']").val(false);
            } else {
                $("[name='criteria.onlyUnderOptimal']").val(true);
            }
            fpSearchRegistry.call(this, e);
        };

        return this.each(function() {
            $(".fpSearch").keyup(fpSearchRegistry);
            $(".fpNavigate").unbind("click").click(function() {
                fpRegistryMoreResults.call(this);
            });
            $(".fpAddButton").unbind("click").click(fpOpenLink);
            $(".fpImportButton").unbind("click").click(importFromBaseLinker);
            $(".fpDataTable").change(onTableChange);
            $(".filtering-under-optimal").unbind("click").click(filteringUnderOptimal);
            $(".fpSort").click(fpSortRegistry);
            $(".construction-detail-item-name, .construction-detail-item-new").unbind("click").click(onConstructionDetailClick);
            $(".load-products-to-order").unbind("click").click(loadProductsToOrder);
            new ExcelExport().bind();
            $("*[title]:not([data-toggle='popover'])").tooltip();
            $(".fpDataTable").on("change", "[name='constructionDetail[]']", toggleAddProductsButtonActivity);
        });

    }

}(jQuery));